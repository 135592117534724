import {Alpine} from "@/alpineJS";

export type DataAlertType = {
  show: boolean;
  message: string;
  type: 'success' | 'error';
  className: string;
}

export type AuthType = {
  step: number,
  layout: string,
  dataAlert: DataAlertType
  resetDataAlert: (this: AuthType) => void;
}

export type VerifyEmailType = {

}

/** Global variable alpine in auth page */
export const initStoreAuth = () => {
  Alpine.store('auth', {
    step: 1,
    layout: 'sign-in',
    dataAlert: {
      show: false,
      message: '',
      type: 'success',
      className: 'alert-success',
    },
    resetDataAlert(this: AuthType) {
      this.dataAlert = {
        show: false,
        message: '',
        type: 'success',
        className: 'alert-success',
      } as DataAlertType;
    },
  })
}
